<template>
  <section class="l-content">
    <el-card name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="资产分类">
          <l-select v-model="condition.categoryCode" :remote="$api.assetCategoryList"></l-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="资产编号|资产名称"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
        <el-form-item class="fr">
          <el-button type="primary" @click="categoryManage()" icon="el-icon-s-operation">分类管理</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="assetName" slot-scope="scope">
          <el-button type="text" @click="$site.view_asset_detail(scope.row.assetId)">{{ scope.row.assetName }}</el-button>
        </template>
        <template slot="borrowFlag" slot-scope="scope">
          <i v-if="scope.row.borrowFlag" class="el-icon-success text-green"></i>
          <i v-else class="el-icon-error text-red"></i>
        </template>
        <template slot="reponsibleUserName" slot-scope="scope">
          <span>{{ scope.row.reponsibleUserName }}</span>
          <el-button type="primary" class="fr" @click="setReponsibleUser(scope.row)" icon="el-icon-edit" circle size="mini"></el-button>
        </template>
        <template slot="useUserName" slot-scope="scope">
          <span>{{ scope.row.useUserName }}</span>
          <el-button type="primary" class="fr" @click="setUseUser(scope.row)" icon="el-icon-edit" circle size="mini"></el-button>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import assetAddoredit from './asset_addoredit'
import assetCategory from './asset_category'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'AssetList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '资产名称', slot: 'assetName' },
          { label: '资产编号', prop: 'assetCode' },
          { label: '资产分类', prop: 'categoryFullName' },
          { label: '资产类型', prop: 'assetTypeName' },
          { label: '可借性', slot: 'borrowFlag' },
          { label: '责任人', slot: 'reponsibleUserName' },
          { label: '使用人', slot: 'useUserName' }
        ],
        operation: {
          width: '160',
          header: { type: 'warning', icon: 'el-icon-plus', label: '新增资产', click: this.add },
          buttons: [{ type: 'primary', icon: 'el-icon-edit', label: '编辑', click: this.edit }]
        }
      },
      condition: {
        // enterpriseCode: this.userInfo.enterpriseCode,
        keyword: '',
        categoryCode: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/asset/list', ['enterpriseCode', this.condition])
    },
    async add() {
      await this.$lgh.openDialog({
        ins: assetAddoredit,
        title: '新增资产',
        data: {
          dataInfo: {
            enterpriseCode: this.userInfo.enterpriseCode,
            borrowFlag: true
          }
        }
      })
      this.$lgh.toastSuccess('新建资产信息成功')

      this.getPageData()
    },
    async edit(item) {
      await this.$lgh.openDialog({
        ins: assetAddoredit,
        title: '修改资产信息',
        data: {
          dataInfo: Object.assign({}, item)
        }
      })
      this.$lgh.toastSuccess('修改资产信息成功')
      this.getPageData()
    },
    setReponsibleUser(item) {
      this.$site.sel_enterpriseEmployee(this.userInfo.enterpriseCode, { userId: item.reponsibleUserId }).then((user) => {
        this.$lgh
          .get('api/enterprise/asset/set/reponsibleuser', {
            assetId: item.assetId,
            userId: user.userId,
            recordUserId: this.userInfo.userId,
            recordUserName: this.userInfo.fullName
          })
          .then((e) => {
            this.getPageData()
          })
      })
    },
    setUseUser(item) {
      this.$site.sel_enterpriseEmployee(this.userInfo.enterpriseCode, { userId: item.useUserId }).then((user) => {
        this.$lgh
          .get('api/enterprise/asset/set/useuser', {
            assetId: item.assetId,
            userId: user.userId,
            recordUserId: this.userInfo.userId,
            recordUserName: this.userInfo.fullName
          })
          .then((e) => {
            this.getPageData()
          })
      })
    },
    async categoryManage() {
      await this.$lgh.openDialog({
        ins: assetCategory,
        title: '资产分类管理',
        data: {
          enterpriseCode: this.userInfo.enterpriseCode
        },
        onclosed() {}
      })
    }
  }
}
</script>
